/**
 * It takes a string and returns its compact version that the middle
 * part of the string is replaced with an ellipsis.
 * @param {string} text - The text to be compacted.
 * @param {number} visibleCharacterCount - The number of characters to show on each side of the ellipsis.
 */
const compactMiddle = (text: string, visibleCharacterCount = 4) => {
  const visibleLength = Math.abs(visibleCharacterCount);

  if (!text?.length) return '-';
  if (text.length <= 2 * visibleLength) return text; // 2: two sides

  const splitted = text.split('');
  return splitted.slice(0, visibleLength).join('') + '...' + splitted.slice(-visibleLength).join('');
};

/**
 * It takes a string and returns its compact version that the end
 * part of the string is replaced with an ellipsis. Use it if the font is mono space.
 * @param {string} text - the text to be compacted
 * @param {number} visibleCharacterCount - The number of characters to show on start side of the ellipsis.
 */
const compactEnd = (text: string, visibleCharacterCount = 16) => {
  const visibleLength = Math.abs(visibleCharacterCount);

  if (!text?.length) return '-';
  if (text.length <= 1 * visibleLength) return text; // 1: one side

  const splitted = text.split('');
  return splitted.slice(0, visibleLength).join('') + '...';
};

export const compact = {
  middle: compactMiddle,
  end: compactEnd,
};
