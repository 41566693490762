import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';
import { tokenStore } from '@ping/stores/token.store';

import { WSConnect } from './connection';
import { WEBSOCKET_URL_NOTIFICATION } from '@ping/configs';
import { socketConnectivityChecker } from '@ping/helpers/socket-connectivity-checker.helper';
import * as gtm from '@ping/helpers/gtm.helper';
import * as Sentry from '@sentry/react';

export enum KycNotificationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Initiated = 'Initiated',
  InitiationFailed = 'InitiationFailed',
  InitiationSubmitted = 'InitiationSubmitted',
  KycReceived = 'KycReceived',
  ValidityCheck = 'ValidityCheck',
  ValiditySucceeded = 'ValiditySucceeded',
  ValidityFailed = 'ValidityFailed',
  Confirmed = 'Confirmed',
  ConfirmFailed = 'ConfirmFailed',
  ConfirmSubmitted = 'ConfirmSubmitted',
  CallbackSucceeded = 'CallbackSucceeded',
  CallbackFailed = 'CallbackFailed',
  FinishSucceeded = 'FinishSucceeded',
  FinishFailed = 'FinishFailed',
  PingReceivedKyc = 'PingReceivedKyc',
}

export enum WithdrawNotificationStatus {
  SignatureVerified = 'SignatureVerified',
  WithdrawalCreated = 'WithdrawalCreated',
  WithdrawalFailed = 'WithdrawalFailed',
}

export enum FormANotificationStatus {
  SignatureVerified = 'SignatureVerified',
  SignatureVerificationFailed = 'SignatureVerificationFailed',
}

export enum NotificationEvents {
  OnWithdrawalStatusChanged = 'OnWithdrawalStatusChanged',
  OnKycStatusChanged = 'OnKycStatusChanged',
  OnOrderStatusChanged = 'OnOrderStatusChanged',
  OnFormAStatusChanged = 'OnFormAStatusChanged',
}

export type NotificationTypes =
  | KycNotificationStatus
  | WithdrawNotificationStatus
  | FormANotificationStatus
  | NotificationEvents;

type TNotificationData = {
  status: NotificationTypes;
};

export const useNotificationWS = (callback?: any) => {
  const [notificationData, setNotificationData] = useState<TNotificationData>(null);
  const tokenQuery = `${tokenStore.getAccessToken()}`;
  const callBackEvent = useRef(null);
  const connectionRef = useRef<HubConnection>(null);

  useEffect(() => {
    if (tokenQuery === 'undefined') {
      return;
    }

    const connection = WSConnect(`${WEBSOCKET_URL_NOTIFICATION}?access_token=${tokenQuery}`);
    connectionRef.current = connection;

    connection.onclose(() => {
      gtm.webSocketDisconnectedEvent('Notifications');
      socketConnectivityChecker('Notifications', false);
      Sentry.captureMessage('[Websocket] Notifications Socket Disconnected');
    });

    connection.onreconnected(async () => {
      try {
        await handleInitialConnection();
        socketConnectivityChecker('Notifications', true);
        Sentry.captureMessage('[Websocket] Notifications Socket reconnected');
      } catch (e) {
        Sentry.captureException(e);
      }
    });

    callBackEvent.current = (event: NotificationTypes, eventCallBack) => {
      connection.on(event, message => {
        const messageData = JSON.parse(message);
        setNotificationData(messageData);
        eventCallBack?.(messageData);
        callback?.(messageData);
      });
    };

    const handleInitialConnection = async () => {
      if (connection.state === HubConnectionState.Disconnected) {
        await connection.start();
      }
      if (connection.state === HubConnectionState.Disconnecting) {
        /* For some reason onClose not works we need to check that later that's why we use setTimeout for now  */
        await new Promise(resolve => {
          /* run first time with no delay */
          const checkConnection = async () => {
            if (connection.state === HubConnectionState.Disconnected) {
              await connection.start();
            }
            connection.state === HubConnectionState.Connected ? resolve(null) : setTimeout(checkConnection, 200);
          };
          checkConnection();
        });
      }
    };

    handleInitialConnection().then(() => {
      socketConnectivityChecker('Notifications', connection.state === HubConnectionState.Connected);
    });

    return () => {
      connection.off(NotificationEvents.OnWithdrawalStatusChanged);
      connection.off(NotificationEvents.OnKycStatusChanged);
      connection.off(NotificationEvents.OnOrderStatusChanged);
    };
  }, []);

  useEffect(() => {
    if (connectionRef?.current?.state) {
      socketConnectivityChecker('Notifications', connectionRef?.current?.state === HubConnectionState.Connected);
    }
  }, [connectionRef?.current?.state]);

  return { notificationData, notificationCallBack: callBackEvent.current };
};
