import { compact } from '@ping/utils';
import clsx from 'clsx';

import style from './style.module.scss';

interface ICompactMiddleProps extends ICustomizable {
  text: string;
  middle: true;
  end?: undefined;
  visibleCharacterCount?: number;
  isExpanded?: boolean;
  onClick?: () => void;
}

interface ICompactEndProps extends ICustomizable {
  text: string;
  middle?: undefined;
  end: true;
  visibleCharacterCount?: number;
  isExpanded?: boolean;
  onClick?: () => void;
}

/**
 * It takes a string and returns a compact version of it
 * @param {ICompactMiddleProps | ICompactEndProps} props - ICompactMiddleProps | ICompactEndProps
 * @returns A React component that takes in a text prop and returns a span with the text inside.
 */
export const Compact = (props: ICompactMiddleProps | ICompactEndProps) => {
  const text = props.middle
    ? props.isExpanded
      ? props.text
      : compact.middle(props.text, props.visibleCharacterCount || 4)
    : props.end
    ? props.isExpanded
      ? props.text
      : compact.end(props.text, props.visibleCharacterCount || 16)
    : '-';

  const classname = clsx(
    style['compact'],
    {
      [style['compact__expanded']]: props.isExpanded,
    },
    props.className
  );

  return (
    <span
      className={classname}
      data-middle={props.middle}
      data-end={props.end}
      title={props.text}
      onClick={props.onClick}
    >
      {text}
    </span>
  );
};
