import { ButtonA11Y } from '@ping/uikit';
import { clsx } from 'clsx';

import { FieldBox } from '../FieldBox';

import { ComboFieldContext } from './combo-field.context';
import style from './style.module.scss';

import type { IButtonA11YProps } from '@ping/uikit/ButtonA11Y';

interface IComboFieldProps extends ICustomizable {
  invalid?: boolean;
  children: React.ReactNode;
  isDisabled?: boolean;
}

interface IComboFieldButtonProps extends ICustomizable, IButtonA11YProps {
  magnet?: IMagnet;
  wrapperClassName?: string;
}

export const ComboField = ({ className, invalid, isDisabled, children, ...restProps }: IComboFieldProps) => (
  <div {...restProps} className={clsx(style['combo-field'], className)} data-disabled={isDisabled}>
    <ComboFieldContext.Provider value={{ invalid }}>{children}</ComboFieldContext.Provider>
  </div>
);

const ComboFieldButton = ({ className, magnet, children, ...buttonProps }: IComboFieldButtonProps) => (
  <FieldBox magnet={magnet}>
    <ButtonA11Y {...buttonProps} className={clsx(style['combo-field-button'], className)}>
      {children}
    </ButtonA11Y>
  </FieldBox>
);

const ComboFieldDivider = ({ className }: ICustomizable) => (
  <FieldBox magnet='both'>
    <span className={clsx(style['combo-field-divider'], className)} />
  </FieldBox>
);

ComboField.Button = ComboFieldButton;
ComboField.Divider = ComboFieldDivider;
ComboField.Box = FieldBox;
